<!-- =========================================================================================
    File Name: CardBasic.vue
    Description: Basic Cards
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page">
    <!-- Caroussel de la page d'accueil -->
    <!-- swiper -->
    <swiper :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl" :options="swiperOption">
      <div class="parallax-bg" data-swiper-parallax="-23%" slot="parallax-bg"></div>
      <swiper-slide :key="slider.title" class="swiper-slide no-padding" v-for="slider in sliders">
        <div class="content-slider">
          <a :href="slider.link" class="link-slider">
            <img :src="slider.background">
          </a>
          <!--<a :href="slider.link" class="link-slider">
            <img :src="slider.background">
            <div class="title" data-swiper-parallax="-100" v-html="slider.title"></div>
            <div class="subtitle font-semibold text-left" data-swiper-parallax="-200" v-html="slider.subtitle"></div>
            <div class="text" data-swiper-parallax="-300">
              <p class="font-medium" v-html="slider.prix"></p>
            </div>
            <div class="text" data-swiper-parallax="-300">
              <div class="subtitle font-semibold text-left" v-html="slider.subtitle2"></div>
              <img class="image2" :src="slider.image2">
              <p class="subtitle font-bold text-left" v-html="slider.subtitle3"></p>
            </div>
          </a>-->
        </div>
      </swiper-slide>
      <!--<div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
      <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-white" slot="button-next"></div>-->
    </swiper>
    <div class="bloc center fonctionnement">
      <vs-row vs-w="12">
        <vs-col class="space-10" vs-align="center" vs-justify="center" vs-lg="3" vs-sm="6" vs-xs="12">
          <img src="@/assets/images/icones/agenda-white.png">
          <p>Mise en place d’un agenda<br> partagé</p>
        </vs-col>
        <vs-col  class="space-10" vs-align="center" vs-justify="center" vs-lg="3" vs-sm="6"
                 vs-xs="12">
          <img src="@/assets/images/icones/reseau-white.png">
          <p>Présentation des points forts <br>du réseau et du négociateur</p>
        </vs-col>
        <vs-col  class="space-10" vs-align="center" vs-justify="center" vs-lg="3" vs-sm="6"
                 vs-xs="12">
          <img src="@/assets/images/icones/notif-white.png">
          <p>RDV notifié par mail <br>et par SMS</p>
        </vs-col>
        <vs-col class="space-10" vs-align="center" vs-justify="center" vs-lg="3" vs-sm="6"
                vs-xs="12">
          <img src="@/assets/images/icones/suiv-white.png">
          <p>Visibilité et suivi en temps<br> réel de la commande</p>
        </vs-col>
      </vs-row>
    </div>

    <div class=" body">
      <!-- content -->
      <div class="center">

        <div class="bloc packs" id="packs">
          <h1>
            Les packs
          </h1>
          <vs-row vs-w="12" align="center"
                  justify="center">
            <vs-col :vs-lg="nbFormules" vs-sm="12" vs-xs="12"
                    class="space-10"
                    v-for="formule in formules" :key="formule.id">
              <vx-card class="center equal">
                <img v-if="formule.mediaIcone !== undefined" :src="baseURL+formule.mediaIcone.contentUrl"
                     class="packIcon">
                <h2> {{formule.libelle}}</h2>
                <div v-html="formule.description">
                </div>

                <div class="prix">
                  <h5 v-for="{nb,prix} in formule.prix" :key="nb">
                    Pack {{nb}} rdv : {{prix}}€ HT
                  </h5>
                </div>
                <vs-button class="mt-4 mr-2 shadow-lg" type="gradient" :color="colors.primary"
                           :gradient-color-secondary="colors.primary"
                           @click="linkAdd(formule.libelle)">Passer une commande
                </vs-button>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
        <div class="bloc packs" id="offres" v-if="parseInt(nbOffres) > 0">
          <h1>
            Les Offres actuelles
          </h1>
          <div class="container" style="padding-top: 0 ; padding-bottom: 0">
            <vs-row vs-w="12" align="center"
                    justify="center">
              <vs-col :vs-lg="nbOffres" vs-sm="12" vs-xs="12"
                      class="space-10"
                      v-for="offre in offres" :key="offre.id">
                <vx-card  class="center equal" :style="(offre.mediaHomePage !== null && offre.mediaHomePage !== undefined) ?{ backgroundImage: 'url(' + `${baseURL+offre.mediaHomePage.contentUrl}` + ')'  } : {backgroundColor: white}">
                <div class="background-card" >
                    <h5 class="formule-titre"> {{offre.formuleCommande.libelle}}</h5>
                  <h2> {{offre.libelle}}</h2>
                  <div v-html="offre.description">
                  </div>

                  <vs-button class="mt-4 mr-2 shadow-lg" type="gradient" :color="colors.primary"
                             :gradient-color-secondary="colors.primary"
                             @click="linkAdd(offre.formuleCommande.libelle, offre.id)">Passer une commande
                  </vs-button>
                  </div>
                </vx-card>
              </vs-col>
            </vs-row>
          </div>
        </div>
        <div class="bloc">
          <div class="vx-row ">
            <call-to-action :subtitle="soustitre" background="Slider/bandeau.png" color="black"
                            link_params='{"type":"Debutant"}' link_text="Profitez de l'offre" link_url="user-orders-add"
                            title="Vous êtes débutant dans l'immobilier" v-if="debutant"/>

          </div>
        </div>
      </div>
  </div>
  </div>
</template>

<script>
import header from '@/assets/images/pages/header_page_commande.png';
import axios from '../../../axios';
import { colors } from '../../../../themeConfig';
import 'swiper/dist/css/swiper.min.css';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import callToAction from '../../components/CallToAction.vue';
import router from '../../../router';
import moment from 'moment';

const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  props: ['connected', 'promotion'],
  components: {
    swiper,
    swiperSlide,
    'call-to-action': callToAction,
  },
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
        autoplay: true,
        slidesPerView: 1,
        paginationClickable: true,
        spaceBetween: 30,
        keyboardControl: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      nbOffres: 0,
      offresPourNego: [],
      offres: [],
      nbFormules: 0,
      baseURL,
      formules: [],
      soustitre: String,
      debutant: false,
      colors,
      result: null,
      responseData: '',
      test: '',
      colorx: 'colors.gray',
      popupActive: false,
      linkHistorique: '/Historique',
      sliders: {
        0: {
          classe: 'premium',
          background: header,
          link: '#packs',
        },
      },
    };
  },
  methods: {
    getFormules() {
      axios.get('/api/formule_commandes', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          // this.formules = response.data
          let nb = 0;
          const formulesPack = [];
          response.data.forEach((x) => {
            if (x.active && x.homePage) {
              formulesPack.push(x);
              this.formules.push(x);
              nb++;
            }
          });

          // Nombre de colonnes pour les packs
          this.nbFormules = Math.floor(12 / nb);
        }
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },
    getOffres() {
      this.checkOffers().then(() => {
        axios.get('/api/sous_formule_commandes', {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then((response) => {
          if (response.data !== null) {
            let nb = 0;
            const formulesPack = [];
            response.data.forEach((x) => {
              if (x.active && (this.offresPourNego.indexOf(x.id) != -1)) {
                formulesPack.push(x);
                this.offres.push(x);
                nb++;
              }
              if (x.mediaSlider !== null && x.slider !== false && (this.offresPourNego.indexOf(x.id) != -1)) {
                this.sliders[x.id] = {
                  title: x.libelle,
                  classe: 'premium',
                  background: baseURL + x.mediaSlider.contentUrl,
                  link: '#offres',

                };
              }
            });
            // Nombre de colonnes pour les packs
            this.nbOffres = Math.floor(12 / nb);
          }
        })
          .catch((error) => {
            this.$toasted.show(error).goAway(1500);
          });
      });
    },
    // Negociateur bénificiant d'une offre débutant
    checkDebutant() {
      axios.get(`/api/commandes/check_offre_debutant?trigramme=${this.$store.state.AppActiveUser.user.trigramme}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          if ((new Date() < new Date(response.data.dateFin)) && (response.data.dateCommande == null)) {
            const date = moment(String(response.data.dateFin)).format('MM/DD/YYYY');
            this.debutant = true;
            this.soustitre = `Vous avez une offre débutant gratuite de ${response.data.nbRdv} rendez-vous valable jusqu'au ${date}`;
          }
        }
      }).catch(() => {
        this.$toasted.show('Une erreur est survenue! Veuillez actualiser la page.').goAway(1500);
      });
    },
    linkAdd(type, promotion) {
      router.push({ name: 'user-orders-add', query: { type, promotion } });
    },
    async checkOffers() {
      await axios.get(`/api/offres/email/${this.$store.state.AppActiveUser.user.email}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          response.data.forEach((x) => {
            this.offresPourNego.push(x.id);
          });
        }
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },
  },
  mounted() {
    this.checkDebutant();
    this.getFormules();
    this.getOffres();
  },
};
</script>
<style>
  [dir] p {
         clear: both;
         margin-bottom: 0px !important;
       }
  .image2 {
    width: 130px
  }
  .temoignage img {
    display: block;
  }

  .temoignage .subtitle {
    font-size: 18px;
    color: white
  }

  .temoignage .nom {
    font-size: 16px;
    color: #E61E51;
    font-weight: 700;
  }

  .temoignage .content-slider {
    padding: 50px !important;
  }

  .temoignage {
    text-align: center;
    background: url("../../../assets/images/Slider/temoignage.png");
    background-size: cover;
  } .content-slider {
      padding: 0;
    }
  .content-slider img {width: 100%}
  .swiper-slide.no-padding {
    font-size: 0;
    padding: 0;}
</style>
